import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Setup from '@/components/Setup'
import Privacy from '@/components/Privacy'

Vue.use(Router)

// Scroll behavior
const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    // scroll to anchor
    return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
  }
  
  // Scroll to top
  return window.scrollTo({ top: 0, behavior: 'smooth' });
}

export default new Router({
  mode: 'history',
  scrollBehavior,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/setup',
      name: 'setup',
      component: Setup
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    }
  ]
})
