<template>
  <v-container fluid>
    <v-layout ref="mainLayout" row wrap align-center class="py-5">
      <v-flex xs12 md6 offset-md3>
        <v-card class="pa-3 elevation-0 transparent">
          <!-- Header -->
          <v-card-title primary-title>
            <h1 class="black--text mb-2 display-2 text-xs-center">Privacy policy</h1>
          </v-card-title>

          <v-card-text class="subheading">
            <p>
              Arthur Vimond built the Oscleton app as a Free app. This Service is provided by
              Arthur Vimond at no cost and is intended for use as is.
            </p>
            <p>
              This page is used to inform visitors regarding the policies with the collection, use, and disclosure
              of Personal Information if anyone decided to use the Service.
            </p>
            <p>
              If you choose to use the Service, then you agree to the collection and use of information in
              relation to this policy. The Personal Information that I collect is used for providing and improving
              the Service. I will not use or share your information with anyone except as described
              in this Privacy Policy.
            </p>
            <p>
              The terms used in this privacy policy have the same meanings as in the terms and conditions, which is
              accessible at Oscleton unless otherwise defined in this privacy policy.
            </p>
            <p>
              <strong>Information Collection and Use</strong>
            </p>
            <p>
              For a better experience, while using the Service, I may require you to provide me with certain
              personally identifiable information. The information that I request will be retained on your device and is not collected by me in any way.
            </p>
            <p>The app does use third party services that may collect information used to identify you.</p>
            <p>Link to privacy policy of third party service providers used by the app</p>
              <ul>
                <li>
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                  >Google Play Services</a>
                </li>
                <li>
                  <a
                    href="http://try.crashlytics.com/terms/privacy-policy.pdf"
                    target="_blank"
                  >Crashlytics</a>
                </li>
              </ul>
            <p></p>
            <p>
              <strong>Log data</strong>
            </p>
            <p>
              I want to inform you that whenever you use the Service, in a case of
              an error in the app I collect data and information (through third party products) on your phone
              called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address,
              device name, operating system version, the configuration of the app when utilizing the Service,
              the time and date of your use of the Service, and other statistics.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
              These are sent to your browser from the websites that you visit and are stored on your device's internal
              memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the app may use third party code and
              libraries that use “cookies” to collect information and improve their services. You have the option to
              either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
              to refuse the cookies, you may not be able to use some portions of this Service.
            </p>
            <p>
              <strong>Service providers</strong>
            </p>
            <p>I may employ third-party companies and individuals due to the following reasons:</p>
            <ul>
              <li>To facilitate the Service;</li>
              <li>To provide the Service on my behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist me in analyzing how the Service is used.</li>
            </ul>
            <p>
              I want to inform users of this Service that these third parties have access to
              your Personal Information. The reason is to perform the tasks assigned to them on my behalf. However,
              they are obligated not to disclose or use the information for any other purpose.
            </p>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              I value your trust in providing me your Personal Information, thus I am striving
              to use commercially acceptable means of protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee
              its absolute security.
            </p>
            <p>
              <strong>Links to other sites</strong>
            </p>
            <p>
              This Service may contain links to other sites. If you click on a third-party link, you will be directed
              to that site. Note that these external sites are not operated by me. Therefore, I strongly
              advise you to review the Privacy Policy of these websites. I have no control over
              and assume no responsibility for the content, privacy policies, or practices of any third-party sites
              or services.
            </p>
            <p>
              <strong>Children’s privacy</strong>
            </p>
            <p>
              These Services do not address anyone under the age of 13. I do not knowingly collect
              personally identifiable information from children under 13. In the case I discover that a child
              under 13 has provided me with personal information, I immediately delete this from
              the servers. If you are a parent or guardian and you are aware that your child has provided me with personal
              information, please contact me so that I will be able to do necessary actions.
            </p>
            <p>
              <strong>Changes to this privacy policy</strong>
            </p>
            <p>
              I may update the Privacy Policy from time to time. Thus, you are advised to review
              this page periodically for any changes. I will notify you of any changes by posting
              the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
            </p>
            <p>
              <strong>Contact me</strong>
            </p>
            <p>If you have any questions or suggestions about the Privacy Policy, do not hesitate to contact me.</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      setupStep: 0,
      midiRemoteScriptUrl:
        "http://download.oscleton.com/midi-remote-script/0.2.0/oscleton.zip"
    };
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
    goToPreviousStep() {
      this.setupStep--;
    },
    goToNextStep() {
      this.setupStep++;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
code {
  color: #555;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.screenshot {
  width: 100%;
  max-width: 600px;
}
</style>
