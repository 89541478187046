<template>
    <v-container fluid>

        <v-layout ref="mainLayout" row wrap align-center class="pt-5">
            <v-flex xs12 md6 offset-md3>

                <v-card class="pa-3 elevation-0 transparent">

                <!-- Header -->
                <v-card-title primary-title>
                    <h1 class="black--text mb-2 display-2 text-xs-center">Setup</h1>
                </v-card-title>

                <v-card-text class="subheading">
                    <p>Oscleton relies on a custom MIDI Remote Script for Ableton Live.<br>
                        In order to connect your device to Ableton Live, please follow the steps below:</p>
                </v-card-text>

                <!-- Stepper -->
                <v-stepper class="mb-5" v-model="setupStep" vertical>

                    <!-- Step 1 -->
                    <v-stepper-step :complete="setupStep > 1" step="1">
                    Download Oscleton MIDI Remote Script
                    </v-stepper-step>
                    <v-stepper-content step="1">
                    <v-card class="elevation-0">
                        <v-card-text class="subheading">
                            <!-- Live 11+ -->
                            <b>Live 11 and above:</b><br>
                            Download the latest production version of
                            <a :href="python3MidiRemoteScriptUrl">
                                Oscleton MIDI Remote Script
                            </a>
                            on your computer.<br>
                            For beta testers, download this
                            <a :href="python3BetaMidiRemoteScriptUrl">
                                Oscleton MIDI Remote Script
                            </a> version instead.<br><br>

                            <!-- Live 10- -->
                            <b>Live 10 and below:</b><br>
                            Download the latest production version of
                            <a :href="python2MidiRemoteScriptUrl">
                                Oscleton MIDI Remote Script
                            </a>
                            on your computer.<br>
                            For beta testers, download this
                            <a :href="python2BetaMidiRemoteScriptUrl">
                                Oscleton MIDI Remote Script
                            </a> version instead.<br><br>


                            Once the download is finished, extract the "Oscleton" folder from the ZIP, and proceed to the next step.
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="goToNextStep">Next</v-btn>
                    </v-stepper-content>

                    <!-- Step 2 -->
                    <v-stepper-step :complete="setupStep > 2" step="2">Add it to Ableton Live resources</v-stepper-step>
                    <v-stepper-content step="2">
                    <v-card class="elevation-0">
                        <v-card-text class="subheading">
                            Put the "Oscleton" folder in the Ableton Live MIDI Remote Scripts folder as follow:
                            <ul>
                                <li class="mt-2">On Mac: </li>
                                <code>/Applications/Live x.x/Contents/App-Resources/MIDI Remote Scripts/Oscleton</code>
                                <br>
                                <li class="mt-2">On Windows: </li>
                                <code>C:\ProgramData\Ableton\Live x.x\Resources\MIDI Remote Scripts\Oscleton</code>
                            </ul>
                        </v-card-text>    
                    </v-card>
                    <v-btn flat @click="goToPreviousStep">Back</v-btn>
                    <v-btn color="primary" @click="goToNextStep">Next</v-btn>
                    </v-stepper-content>

                    <!-- Step 3 -->
                    <v-stepper-step :complete="setupStep > 3" step="3">Select Oscleton MIDI Control Surface in Ableton Live</v-stepper-step>
                    <v-stepper-content step="3">
                    <v-card class="elevation-0">
                        <v-card-text class="subheading">
                            Start Ableton Live, and open the Preferences.
                            In the MIDI section, select "Oscleton" in the Control Surface list.
                            <img class="mt-3 center screenshot" src="@/assets/ableton-live-oscleton-control-surface-screenshot.png" alt="Ableton Live Oscleton Control Surface screenshot">
                        </v-card-text>
                    </v-card>
                    <v-btn flat @click="goToPreviousStep">Back</v-btn>
                    <v-btn color="primary" @click="goToNextStep">Next</v-btn>
                    </v-stepper-content>

                    <!-- Step 4 -->
                    <v-stepper-step step="4">Connect your mobile device to your computer</v-stepper-step>
                    <v-stepper-content step="4">
                    <v-card class="elevation-0">
                        <v-card-text class="subheading">
                            In Oscleton app, open the Settings, and create a Connection by providing your computer IP address.
                            <img class="my-4 center screenshot" src="@/assets/oscleton-settings-connection-screenshot.png" alt="Ableton Live Oscleton Control Surface screenshot">
                            To find your IP address on your computer, open the Terminal (on Mac) or the Command Prompt (on Windows) and type the following command:
                            <ul>
                                <li class="mt-2">On Mac: </li>
                                <code>ifconfig | grep "inet "</code>
                                <br>
                                <li class="mt-2">On Windows: </li>
                                <code>ipconfig</code>
                            </ul>
                            <div class="mt-3">Once the connection is created, select it to automatically connect the app to Ableton Live.<br>
                            The selected connection is kept in memory, so the next time you will open Oscleton, it will directly reconnect to Live.</div>
                            <div class="mt-1"><b>Important</b>: Ableton Live must be open and configured before launching Oscleton app.</div>

                            
                            <p class="mt-5 title text-xs-center">Congratulations! You have successfully setup Oscleton.</p>
                            <p class="subheading text-xs-center">You can now enjoy real-time communication between your mobile device and Ableton Live.</p>
                        </v-card-text>
                    </v-card>
                    <v-btn flat @click="goToPreviousStep">Back</v-btn>
                    <v-btn color="primary" @click="goToHome">Done</v-btn>
                    </v-stepper-content>
                </v-stepper>

                </v-card>
            </v-flex>
        </v-layout>

    </v-container>
</template>

<script>
    export default {
        created () {
            
        },
        data () {
            return {
                setupStep: 0,
                python3MidiRemoteScriptUrl: '../download/midi-remote-script/python3/1.2.0/oscleton.zip',
                python3BetaMidiRemoteScriptUrl: '../download/midi-remote-script/python3/1.2.0/oscleton.zip',
                python2MidiRemoteScriptUrl: '../download/midi-remote-script/python2/1.2.0/oscleton.zip',
                python2BetaMidiRemoteScriptUrl: '../download/midi-remote-script/python2/1.2.0/oscleton.zip'
            }
        },
        methods: {
            goToHome() {
                this.$router.push('/');
            },
            goToPreviousStep() {
                this.setupStep--;
            },
            goToNextStep() {
                this.setupStep++;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    code {
        color: #555;
    }

    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .screenshot {
        width: 100%;
        max-width: 600px;
    }
    
</style>
