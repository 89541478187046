<template>
    <v-app>

        <!-- Naivgation drawer -->
        <v-navigation-drawer app fixed temporary right v-model="showDrawer">
        <v-list>
            <!-- Menu items -->
            <v-list-tile v-for="item in menuItems" :key="item.title" router :to="item.link">
                <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>{{ item.title }}</v-list-tile-content>
            </v-list-tile>
        </v-list>
        </v-navigation-drawer>

        <v-toolbar app fixed :scroll-off-screen="true" height="64">
            <router-link to="/" style="cursor: pointer;">
                <img class="mr-3 mt-1" src="@/assets/oscleton-logo.svg" alt="Oscleton logo" height="32">
            </router-link>
            <router-link to="/" style="cursor: pointer;">
                <v-toolbar-title class="ml-3 grey--text text--darken-3" v-text="title"></v-toolbar-title>
            </router-link>
            <v-spacer></v-spacer>

            <!-- Download (Icon) -->
            <v-btn class="accent hidden-sm-and-up" flat fab small icon @click="scrollToDownload">
                <v-icon>cloud_download</v-icon>
            </v-btn>

            <!-- Download (Text) -->
            <v-btn class="accent white--text text--darken-2 mr-4 hidden-xs-only" @click="scrollToDownload">
                <v-icon left>cloud_download</v-icon>
                Download
            </v-btn>

            <v-toolbar-side-icon class="hidden-md-and-up" @click.native.stop="showDrawer = !showDrawer"></v-toolbar-side-icon>
            <v-toolbar-items class="hidden-sm-and-down">
                <!-- Menu items -->
                <v-btn class="grey--text text--darken-2" flat v-for="item in menuItems" :key="item.title" router :to="item.link">
                    <v-icon left class="grey--text text--darken-2">{{ item.icon }}</v-icon>
                    {{ item.title }}
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-content class="pa-0">
            <router-view class="pa-0"></router-view>
        </v-content>

        <!-- Footer -->
        <v-footer class="secondary darken-1 pa-3" height="auto" absolute>
            <v-layout row wrap align-center>
                <!-- Copyright -->
                <v-flex xs6>
                    <div class="white--text">
                        <span class="hidden-sm-and-down">Copyright</span> © 2018-2024 Arthur Vimond
                    </div>
                </v-flex>
                <!-- Made with love -->
                <v-flex xs6>
                    <div class="white--text text-xs-right">
                        <a class="white--text" href="http://arthurvimond.fr" target="_blank">
                            Made
                            <span class="hidden-sm-and-down">with <v-icon small class="white--text">favorite</v-icon></span>
                            by Arthur Vimond
                        </a>
                    </div>
                </v-flex>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
    import Home from './components/Home'

    export default {
        name: 'App',
        components: {
            Home
        },
        data () {
            return {
                title: 'Oscleton',
                showDrawer: false,
                menuItems: [
                    {icon: 'home', title: 'Home', link: '/'},
                    {icon: 'settings', title: 'Setup', link: '/setup'},
                    {icon: 'https', title: 'Privacy', link: '/privacy'},
                ]
            }
        },
        methods: {
            scrollToDownload() {
                this.$router.push('/');
                // Delay needed before scrolling to download
                setTimeout(() => window.scrollTo({ top: document.querySelector('#download').offsetTop, behavior: 'smooth' }), 1)
            }
        }
    }
</script>

<style>

    a:link {
        text-decoration: none;
    }

</style>