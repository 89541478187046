<template>
    <v-container fluid>
        <!-- Landing -->
        <section>
            <v-parallax
                    :src="liveScreenshotUrl"
                    :height="windowHeight * 0.85">
                <v-layout column align-center justify-center class="white--text">
                    <h1 class="display-4 white--text mb-2 hidden-sm-and-down text-xs-center">
                        Oscleton</h1>
                    <h1 class="display-3 white--text mb-2 hidden-md-and-up text-xs-center">
                        Oscleton</h1>
                    <div class="display-1 hidden-sm-and-down mt-1 mb-2 text-xs-center">Ableton Live companion app</div><br>

                    <div class="title font-weight-medium hidden-sm-and-down mt-1 mb-2">Now compatible with Live 12
                    </div>
                    <div class="display-1 hidden-md-and-up mt-1 mb-2 text-xs-center accent">Ableton Live companion app</div>
                    <v-btn class="accent mt-3" dark large @click='scrollToDownload'>
                        Get started
                    </v-btn>
                </v-layout>
            </v-parallax>
        </section>

        <!-- Display Single Knob -->
        <section>
            <v-layout column align-center justify-center class="primary white--text">
                <v-flex class="my-5">
                    <div class="display-2 font-weight-light mx-5 text-xs-center">Monitor and control your Live set mixing changes in real-time
                    </div>

                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mx-4 my-5">
                            <img class="screenshot" src="@/assets/display-single-knob-screenshot-phone-frame-with-shadow.png"
                                 alt="Oscleton display screenshot">
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
        </section>

        <!-- Display Browser -->
        <section>
            <v-layout column align-center justify-center class="secondary white--text">
                <v-flex class="my-5">
                    <div class="display-2 font-weight-light mx-5 text-xs-center">Browse, preview and load any item from your Live library
                    </div>

                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mx-4 my-5">
                            <img class="screenshot" src="@/assets/display-browser-screenshot-phone-frame-with-shadow.png"
                                 alt="Oscleton display screenshot">
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
        </section>

        <!-- Capture MIDI -->
        <section>
            <v-layout column align-center justify-center class="primary white--text">
                <v-flex class="my-5">
                    <div class="display-2 font-weight-light mx-5 text-xs-center">Capture the MIDI notes you just played
                    </div>

                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mx-4 my-5">
                            <img class="screenshot" src="@/assets/display-capture-midi-screenshot-phone-frame-with-shadow.png"
                                 alt="Oscleton display screenshot">
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
        </section>

        <!-- Settings Add connection-->
        <section>
            <v-layout column align-center justify-center class="secondary white--text">
                <v-flex class="my-5">
                    <div class="display-2 font-weight-light mx-5 text-xs-center">Switch between different Layouts
                    </div>

                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mx-4 my-5">
                            <img class="screenshot" src="@/assets/display-layouts-screenshot-phone-frame-with-shadow.png"
                                 alt="Oscleton display screenshot">
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
        </section>

        <!-- Settings Add connection-->
        <section>
            <v-layout column align-center justify-center class="primary white--text">
                <v-flex class="my-5">
                    <div class="display-2 font-weight-light mx-5 text-xs-center">Quick and easy setup
                    </div>

                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mx-4 my-5">
                            <img class="screenshot" src="@/assets/settings-add-connection-screenshot-phone-frame-with-shadow.png"
                                 alt="Oscleton display screenshot">
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
        </section>

        <!-- Powered by SDK -->
        <section>
            <v-layout column align-center justify-center class="grey-GitHub white--text">
                <v-flex class="my-5">
                    <div class="display-2 font-weight-light mx-5 text-xs-center">Powered by its own SDK</div>
                    <div class="headline font-weight-light mx-5 mt-2 text-xs-center">Open-source project on GitHub</div>

                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mx-4 mt-4">
                            <a :href='oscletonSDKGitHubUrl' target="_blank">
                                <img id="gitHubLogo" src="@/assets/github-logo.svg" alt="GitHub logo">
                            </a>
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
        </section>

        <!-- Download -->
        <section id="download">
            <v-layout column align-center justify-center class="primary white--text">
                <v-flex class="my-5">
                    <!-- Play store -->
                    <div class="display-2 font-weight-light mb-3 mx-5 text-xs-center">Available today for Android</div>
                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mb-0">
                            <a :href='googlePlayAppUrl' target="_blank">
                                <img id="playStoreImg" :src='googlePlayBadgeUrl' alt='Get it on Google Play'/>
                            </a>
                        </v-flex>
                    </v-layout>
                    <!-- Beta program -->
                    <div class="headline mb-3 mx-5 text-xs-center">Become a Beta Tester</div>
                    <v-layout column align-center justify-center>
                        <v-flex xs12 md4 sm8 class="mb-5">
                            <a :href='googlePlayAppBetaUrl' target="_blank">
                                <v-btn class="accent mt-0" dark>
                                    Join the Beta program
                                </v-btn>
                            </a>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </section>

        <!-- Hidden (but needed to keep png for social meta cards at build) -->
        <img id="oscletonHiddenImg" :src='hiddenLogoPng'/>
    </v-container>

</template>

<script>
    export default {
        created () {
            this.windowHeight = window.innerHeight
        },
        data () {
            return {
                liveScreenshotUrl: require('@/assets/ableton-live-set-screenshot-dim-75.png'),
                oscletonSDKGitHubUrl: 'https://github.com/ArthurVimond/oscleton-android-sdk',
                googlePlayAppUrl: 'https://play.google.com/store/apps/details?id=com.oscleton',
                googlePlayAppBetaUrl: 'https://play.google.com/apps/testing/com.oscleton/',
                googlePlayBadgeUrl: 'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png',
                hiddenLogoPng: require('@/assets/oscleton-logo.png'),
            }
        },
        methods: {
            scrollToDownload() {
                window.scrollTo({ top: document.querySelector('#download').offsetTop, behavior: 'smooth' })
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    .screenshot {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .grey-GitHub {
        background-color: #333;
    }

    #gitHubLogo {
        width: 100%;
        max-width: 150px;
    }

    #playStoreImg {
        width: 100%;
        max-width: 350px;
    }

    #oscletonHiddenImg {
        width: 0;
        height: 0;
        visibility: hidden;
    }
</style>
